import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  constructor(public authService: AuthService, @Inject(DOCUMENT) private doc: Document) {}

  loginWithRedirect() {
    this.authService.loginWithRedirect();
  }

  logout() {
    this.authService.logout({ returnTo: this.doc.location.origin });
  }
}
