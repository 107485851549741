import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINT_BASEURL, environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(`${ENDPOINT_BASEURL}/`)) {
      return next.handle(request);
    }

    // Add default headers if they are not set
    let headers = request.headers;
    if (request.headers.get('Content-Type') === '') {
      headers = request.headers.set('Content-Type', 'application/json');
    }

    // Get Function Key if it exist in the configs
    const functionKey: string | undefined = environment.apiKey;
    if (functionKey) {
      headers = headers.set('x-functions-key', functionKey);
    }

    return next.handle(
      request.clone({
        headers: headers,
      })
    );
  }
}
