export class AppError {
  errorLevel?: AppErrorLevel;
  statusCode?: number;
  statusText?: string;
  message: string;
  title?: string;
}

export enum AppErrorLevel {
  Info,
  Warning,
  Error,
}
