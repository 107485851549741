<div class="container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="alert alert-danger">
      <div style="text-align: center; margin: 50px">
        <div class="fontSize-40">There is Nothing here...</div>
        <div class="m-50">
          <mat-progress-bar mode="indeterminate" class="progressBar"></mat-progress-bar>
        </div>
        <div class="fontSize-20">Oops!...you may have mistyped the address or the page you are looking for is not found or never existed.</div>
      </div>
    </div>
  </div>
</div>
