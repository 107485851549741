import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ErrorDialogService } from 'src/app/shared/error/error-dialog.service';
import { AppError } from '../../../shared/models/app-error.model';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorDialogService: ErrorDialogService, private zone: NgZone) {}

  handleError(error: Error) {
    //Todo - Client side error - Log this in to SQL table (error.message)
    console.log(error);

    let appError: AppError = {
      message: 'An error occurred in the application. <br/>Please try again or report this to our team.',
    };

    this.zone.run(() => this.errorDialogService.openDialog(appError));
  }
}
