import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, finalize } from 'rxjs/operators';
import { ErrorDialogService } from 'src/app/shared/error/error-dialog.service';
import { LoadingDialogService } from 'src/app/shared/loading/loading-dialog.service';
import { AppError } from '../../../shared/models/app-error.model';
import { FinishedService } from './finished.service';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private errorDialogService: ErrorDialogService,
    private loadingDialogService: LoadingDialogService,
    private finishedService: FinishedService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingDialogService.openDialog();

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadingDialogService.hideDialog();

        let errorTitle = ''; // default value found in error-dialog.component.ts
        let errorMessage = 'Please try again or report this to our team.';
        let handled: boolean = false;

        if (error instanceof HttpErrorResponse) {
          if (!(error.error instanceof ErrorEvent)) {
            if (error.status === 401 || error.status === 403) {
              errorTitle = 'Unauthorized';
              errorMessage = 'Nothing to see here...';
            } else {
            }
          }
        } else {
          console.error('Other Errors');
        }

        if (handled) {
          return of(error);
        } else {
          let appError: AppError = {
            statusCode: error.status,
            statusText: error.statusText,
            message: errorMessage,
            title: errorTitle,
          };

          this.errorDialogService.openDialog(appError);
          return throwError(error);
        }
      }),
      finalize(() => {
        this.loadingDialogService.hideDialog();
        this.finishedService.notify();
      })
    ) as Observable<HttpEvent<any>>;
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      // case 401:      //login
      //     this.router.navigateByUrl("/login");
      //     break;
      // case 403:     //forbidden
      //     this.router.navigateByUrl("/unauthorized");
      //     break;
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }
}
