import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';

@Injectable()
export class FinishedService {
  public finished: EventEmitter = new EventEmitter();
  notify() {
    this.finished.emit('done');
  }
}
