import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppError } from '../../models/app-error.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AppError, private router: Router) {
    if (data && !data.title) {
      data.title = 'An error has occurred!';
    }
  }

  onClose() {
    let statusCode: number = this.data?.statusCode;
    if (statusCode === 401 || statusCode === 403) {
      this.router.navigateByUrl('/home');
    }
  }
}
