<h1 mat-dialog-title>Loading data</h1>
<mat-divider></mat-divider>

<div mat-dialog-content style="margin-top: 25px">We are retrieving your data…</div>

<div mat-dialog-actions>
  <div class="loading-spinner">
    <mat-spinner diameter="40" strokeWidth="2"></mat-spinner>
  </div>
</div>
