<footer class="page-footer">
  <div class="footer-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h4 style="font-size: 22px" class="text-xs-center">Contact Info</h4>
          <p>Florence Office:</p>
          <p>151 W. Evans St. Florence, SC 29501</p>
          <p>(843) 519-0808</p>
          <p>Email: <a href="mailto:CARESOnboardingSupport@synergipartners.com">CARESOnboardingSupport@synergipartners.com</a></p>
          <p class="version">v{{ version }}</p>
        </div>
      </div>
    </div>
  </div>
</footer>
