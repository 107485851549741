import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthTestComponent } from './auth-test/auth-test.component';
import { PageNotFoundComponent } from './shared/error/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'clientintake/:cifNotificationId',
    loadChildren: () => import('./modules/client-intake/client-intake.module').then((m) => m.ClientIntakeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clientinfo/:cifNotificationId',
    loadChildren: () => import('./modules/client-intake/client-intake.module').then((m) => m.ClientIntakeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clientinfo/v2/:cifNotificationId',
    loadChildren: () => import('./modules/client-information-form-v2/cif.module').then((m) => m.CifModule),
    canActivate: [AuthGuard],
  },
  // { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
  // { path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule) },
  { path: 'authTest', component: AuthTestComponent, canActivate: [AuthGuard] },
  { path: '404', component: PageNotFoundComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
