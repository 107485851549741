import { Component } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { AuthTestService } from 'src/app/auth-test.service';

@Component({
  selector: 'app-auth-test',
  templateUrl: './auth-test.component.html',
  styleUrls: ['./auth-test.component.scss'],
})
export class AuthTestComponent {
  responseJson: string;
  audience = this.configFactory.get()?.audience;
  hasApiError = false;

  constructor(private api: AuthTestService, private configFactory: AuthClientConfig) {}

  pingApi() {
    this.api.callApi().subscribe({
      next: (res) => {
        this.hasApiError = false;
        this.responseJson = JSON.stringify(res, null, 2).trim();
        console.log('Request Authenticated and returned SUCCESS!!!!');
      },
      error: () => (this.hasApiError = true),
    });
  }
}
