<nav class="navbar navbar-expand-md bg-light navbar-light fixed-top">
  <!-- <a class="navbar-brand" href="#">Synergi Partners</a> -->
  <a class="navbar-brand" [routerLink]="[]">
    <img class="logo" />
    Synergi Partners
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="'/home'" routerLinkActive="active"
          >Home</a
        >
      </li> -->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="[]" routerLinkActive="active">Client Information</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="'/contact'" routerLinkActive="active"
          >Contact us</a
        >
      </li> -->
    </ul>

    <ul class="navbar-nav">
      <li class="nav-item header-menu-item" *ngIf="(authService.isAuthenticated$ | async) === false">
        <a class="nav-link header-menu-link" (click)="loginWithRedirect()" routerLinkActive="active">Login </a>
      </li>
      <li class="nav-item header-menu-item" *ngIf="authService.isAuthenticated$ | async">
        <a class="nav-link header-menu-link" (click)="logout()" routerLinkActive="active">Logout </a>
      </li>

      <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="color: white;">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>login</mat-icon>
          <span>Login</span>
        </button>
        <button mat-menu-item>
          <mat-icon>logout</mat-icon>
          <span>Log out</span>
        </button>
        <button mat-menu-item>
          <mat-icon>app_registration</mat-icon>
          <span>Register</span>
        </button>
      </mat-menu> -->

      <!-- <li class="nav-item header-menu-item">
        <a
          class="nav-link header-menu-link"
          [routerLink]="'/account/login'"
          routerLinkActive="active"
          >Login
        </a>
      </li>
      <li class="nav-item header-menu-item">
        <a
          class="nav-link header-menu-link"
          [routerLink]="'/account/register'"
          routerLinkActive="active"
          >Register</a
        >
      </li> -->
    </ul>
  </div>
</nav>
