import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth0/auth0-angular';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthTestComponent } from './auth-test/auth-test.component';
import { httpInterceptorProviders } from './core/constants/appConstants';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './core/layout/layout.module';
import { FinishedService } from './core/services/interceptors/finished.service';
import { AppMaterialModule } from './modules/app-material/app-material.module';
import { DialogBodyComponent } from './shared/dialog-body/dialog-body.component';

@NgModule({
    declarations: [AppComponent, AuthTestComponent],
    imports: [
        AuthModule.forRoot({
            ...environment.auth,
            httpInterceptor: {
                ...environment.httpInterceptor,
            },
            // redirectUri: window.location.origin,
        }),
        HttpClientModule,
        AppMaterialModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        CoreModule,
        LayoutModule,
    ],
    providers: [httpInterceptorProviders, FinishedService],
    bootstrap: [AppComponent]
})
export class AppModule {}
